import dayjs from "dayjs";
import { diffuserTypes } from "../constants/broadcast";


export const calculateErrorFromDates = (tableData, broadcastType, callback = undefined) => {
    let currentDateForContinu = null

    const updatedTableData = tableData.map((obj, index) => {
      let isError = true;
      let IsReminderError = true;

      // Here is logic for errors
      const currentDate = dayjs(obj?.date)
      const reminderDate = dayjs(obj?.reminder_content?.date);
      const nextDate = dayjs(tableData[index + 1]?.date);
      const prevDate = tableData?.[index - 1]?.date ? dayjs(tableData[index - 1]?.date) : null
      const today = dayjs();

      // Errors for dynamic continu broadcast
      if (broadcastType === diffuserTypes.timing.value) {
        if (index === 0) {
          currentDateForContinu = dayjs(currentDate);
        }

        if (index === tableData.length - 1) {
          IsReminderError = false;
        }

        // For last training (case for old broadcast, if user tries to edit)
        if (index === tableData.length - 1 && tableData[index]?.step) {
          isError = false
        }

        const nextTrainingStep = tableData[index + 1]?.step;

        if (
          index < tableData.length - 1 &&
          currentDateForContinu &&
          reminderDate.isBefore(
            dayjs(currentDateForContinu?.add(nextTrainingStep, 'day'))
          )
        ) {
          IsReminderError = false;
        }
      }

      // Errors for all trainings, but not for last in the table
      if (index < tableData.length - 1) {
        if (index === 0 && currentDate.isAfter(today)) {
          isError = false;
        } else if (currentDate < nextDate) {
          isError = false;
        }

        if (
          (reminderDate < nextDate && reminderDate > currentDate) ||
          (reminderDate < nextDate && !nextDate)
        ) {
          IsReminderError = false;
        }
        // Errors for last trainings in the end of the table
      } else if (
        index === tableData.length - 1
      ) {

        // If we have inly 1 training in broadcast
        if (currentDate.isAfter(today) && index === 0) {
          isError = false;
        }

        // If broadcast has 1+ trainings, and training is not first
        if (index !== 0 && currentDate.isAfter(today) && currentDate.isAfter(prevDate)) {
          isError = false;
        }

        // Date of training should always be in the future
        if (reminderDate.isAfter(currentDate)) {
          IsReminderError = false;
        }

        // If reminder activated, reminder date should be after its trainings date
        if (obj.reminder && reminderDate.isBefore(currentDate)) {
          IsReminderError = true;
        }
      }

      // Error for first training
      if (broadcastType === diffuserTypes.specific.value) {
        if (index === 0 && currentDate.isBefore(today)) {
          isError = true;
        }
      }

      return {
        ...obj,
        reminder_content: {
          ...obj.reminder_content,
          isError: IsReminderError,
        },
        isError: isError,
      };
    });

    if (callback) callback(updatedTableData);

    return updatedTableData
}