import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  Typography,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box,
  TableSortLabel,
} from '@mui/material';
import './Organization.scss';

import { useLearnerContext } from '../../context/LearnerContext';
import axios from '../../services/app.utils';
import { BASE_URL } from '../../constants/config';
import CreateEditOrganization from './CreateEditOrganization';
import { actionTypes } from '../../constants/types';
import useSortableTable from '../../hooks/useSortTable';
import { ADMIN_COMPANY } from '../../constants/auth';
import TypographyUI from '../TypographyUI';
import { CompanyServices } from '../../services/companies';

const OrganizationTable = () => {
  const { companiesData, organization } = useLearnerContext();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [organizationToDeleteId, setOrganizationToDeleteId] = useState(null);

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    organization,
    {
      key: 'name',
      direction: 'asc',
    }
  );

  const deleteOrganization = (companyId) => {
    setOrganizationToDeleteId(companyId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };
  const confirmDeleteOrganization = async () => {
    if (organizationToDeleteId) {
      await CompanyServices.deleteCompany(organizationToDeleteId);
      companiesData();
    }
    handleCloseDeleteConfirmation();
  };

  return (
    <div>
      <Box className="organization-heading">
        <TypographyUI variant="h5" fontSize="24px" align="left">
          Organisation
        </TypographyUI>

        <CreateEditOrganization type={actionTypes.create} />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="green-border">
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={
                    sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('name')}
                >
                  Organisation
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'monthly_authorized_credits'}
                  direction={
                    sortConfig.key === 'monthly_authorized_credits'
                      ? sortConfig.direction
                      : 'asc'
                  }
                  onClick={() => handleSort('monthly_authorized_credits')}
                >
                  Crédits autorisés mensuel
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'remaining_credits'}
                  direction={
                    sortConfig.key === 'remaining_credits'
                      ? sortConfig.direction
                      : 'asc'
                  }
                  onClick={() => handleSort('remaining_credits')}
                >
                  Crédits restants
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((company) => (
              <TableRow className="green-border" key={company._id}>
                <TableCell className="green-border">{company.name}</TableCell>
                <TableCell className="green-border">
                  {company.contract_credits || company.monthly_authorized_credits}
                </TableCell>
                <TableCell className="green-border">
                  {company.remaining_credits}
                </TableCell>
                <TableCell className="green-border">
                  <Box className="align-cell-center">
                    <CreateEditOrganization
                      id={company._id}
                      companyName={company.name}
                      type={actionTypes.edit}
                    />
                    <Tooltip title="Supprimer">
                      <Button
                        sx={{
                          color: '#4546CD',
                          opacity:
                            company.name.trim() === ADMIN_COMPANY ? '0.3' : '1',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        disabled={company.name.trim() === ADMIN_COMPANY}
                        size="small"
                        startIcon={<i className="fas fa-trash-alt"></i>}
                        onClick={() => deleteOrganization(company._id)}
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette organisation ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDeleteConfirmation}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteOrganization}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrganizationTable;
