import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  FormControlLabel,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  FormControl,
  Radio,
  DialogActions,
  Switch,
  DialogTitle,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import SelectRecipient from './components/SelectRecipient';
import { useLearnerContext } from '../../context/LearnerContext';
import { BroadcastService } from '../../services/broadcast';
import {
  diffuserActionTypes,
  diffuserStatuses,
  diffuserTypes,
} from '../../constants/broadcast';
import { actionTypes } from '../../constants/types';
import EditButton from './components/EditButton';
import AddDayIntoTable from './components/AddDayIntoTable';
import DiffuserFooterActions from './components/DiffuserFooterActions';
import CreateEditDiffuserTableActions from './components/CreateEditDiffuserTableActions';
import EditSelectRecipients from './components/EditSelectRecipients';
import useStore from '../../store/store';
import DialogTitleCustom from '../../components/DialogTitleCustom';
import TypographyUI from '../../components/TypographyUI';
import { extractTextAndLink } from '../../helpers/trainings';

import CalendarIcon from '@mui/icons-material/InsertInvitation';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { calculateErrorFromDates } from '../../helpers/broadcast';


dayjs.extend(duration);

function CreateEditDiffuser({
  type,
  id,
  text = null,
  disabled = false,
  callbackOnClickIcon = null,
  confirmCreateBroadcast = null,
  setConfirmedBroadcastId = null
}) {
  const { selectedLearnerNames, getBroadcasts, setSelectedLearnerNames } =
    useLearnerContext();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [counter, setCounter] = useState(0);
  const [selectionValue, setSelectionValue] = useState(
    diffuserTypes.specific.value
  );
  const [status, setStataus] = useState('');
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [tableData, setTableData] = useState([
    {
      id: 0,
      selectValue: '',
      content: '',
      mandatory: false,
      reminder: false,
      date: dayjs().add(30, 'minute'),
      step: 0,
      reminder_content: {
        content: '',
        date: dayjs().add(4, 'hour'),
        stepsDays: 0,
        stepsHours: 0,
      },
      trainingId: null,
    },
  ]);

  const [open, setOpen] = useState(false);
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const [personalBroadcasts, setPersonalBroadcasts] = useState([]);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const { selectedOrganizationId } = useStore((store) => store);

  const [confirmLaunch, setConfirmLaunch] = useState(false);
  const [creditsInfo, setCreditsInfo] = useState(null);
  const [createdBroadcastId, setCreatedBroadcastId] = useState(null);

  const [copyTableToCompare, setCoptTableToCompare] = useState([]);

  const [menuLimits, setMenuLimits] = useState({
    max_trainings: null,
    min_trainings: null,
  });

  const handleOpen = () => {
    setOpen(true);
    setSelectedLearnerNames([]);
    if (type === actionTypes.edit) {
      getTrainings();
      getBroadcastId();
    }
    if (type === actionTypes.create) getTrainings();
  };
  const handleClose = () => {
    setOpen(false);
    setCoptTableToCompare([]);
    if (callbackOnClickIcon) callbackOnClickIcon();
  };

  const { getTrainings, trainings } = useLearnerContext();

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleContent = (e) => {
    setContent(e.target.value);
  };

  const resetTable = () => {
    setContent('');
    setTitle('');
    setCounter(0);
    setTableData([
      {
        id: 0,
        selectValue: '',
        content: '',
        mandatory: false,
        reminder: false,
        date: dayjs(),
        step: 0,
        reminder_content: {
          content: '',
          date: dayjs().add(4, 'hour'),
          stepsDays: 0,
          stepsHours: 0,
        },
        trainingId: null,
      },
    ]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitBtnDisabled(true);

    if (type === actionTypes.create) await createDiffuser();
    if (type === actionTypes.edit) await updateDiffuser();

    setIsSubmitBtnDisabled(false);
  };

  const createDiffuser = async (status = diffuserStatuses.DRAFT.value) => {
    const isTitleEmpty = title.trim() === '';
    const isContentEmpty = content.trim() === '';
    const hasSelectedTrainings = tableData.some(
      (row) => row.selectValue !== ''
    );

    if (
      isTitleEmpty ||
      isContentEmpty ||
      (selectionValue !== diffuserTypes.manual.value && !hasSelectedTrainings)
    ) {
      return;
    }

    if (
      selectionValue === diffuserTypes.manual.value &&
      (!menuLimits.min_trainings ||
        !menuLimits.max_trainings ||
        menuLimits.min_trainings > menuLimits.max_trainings)
    ) {
      return setSnackbarContent(
        'error',
        'Veuillez sélectionner le nombre approprié de formations'
      );
    }
    const trainingData = {};

    tableData.forEach((row, index) => {
      const selectedTraining = trainings.find(
        (training) => training.name === row.selectValue
      );
      if (selectedTraining) {
        trainingData[selectedTraining._id] = {
          name: selectedTraining.name,
          date: row.date.toISOString(),
          step: row.step,
          mandatory: row.mandatory,
          reminder: row.reminder,
          reminder_content: row?.reminder_content,
        };
      }
      if (!selectedTraining) {
        const order = index + 1;
        trainingData['draft' + order] = {
          name: 'draft' + order,
          date: row.date.toISOString(),
          step: row.step,
          mandatory: row.mandatory,
          reminder: row.reminder,
          reminder_content: row?.reminder_content,
        };
      }
    });

    const dataBroadcast = {
      name: title,
      description: content,
      type: selectionValue,
      subscriptionControl: isUnsubscribe,
      company_id: selectedOrganizationId,
      status: status,
      recipients: selectedLearnerNames,
      trainingsTable: tableData,
      trainings: trainingData,
      manual_broadcast: {
        max_trainings: menuLimits.max_trainings,
        min_trainings: menuLimits.min_trainings,
      },
    };

    if (!dataBroadcast.manual_broadcast.max_trainings)
      delete dataBroadcast.manual_broadcast;

    try {
      setSnackbarContent(
        'info',
        type === actionTypes.create
          ? 'Créer peut prendre un certain temps'
          : 'La copie de la diffusion peut prendre un certain temps'
      );
      const response = await BroadcastService.createBroadcast(dataBroadcast);
      const tempId = status && response.data.data._id;
      if (tempId) {
        setCreatedBroadcastId(tempId);
      }

      setSnackbarContent('success', 'Parcours enregistré avec succès');
      handleClose();
      getBroadcasts();
      resetTable();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const updateDiffuser = async (newStatus = status) => {
    const trainingData = {};

    if (
      selectionValue === diffuserTypes.manual.value &&
      (!menuLimits.min_trainings ||
        !menuLimits.max_trainings ||
        menuLimits.min_trainings > menuLimits.max_trainings)
    ) {
      return setSnackbarContent(
        'error',
        'Veuillez sélectionner le nombre approprié de formations'
      );
    }

    tableData.forEach((row, index) => {
      const selectedTraining = trainings.find(
        (training) => training.name === row.selectValue
      );

      if (selectedTraining) {
        trainingData[selectedTraining._id] = {
          name: selectedTraining.name,
          date: row.date.toISOString(),
          step: row.step,
          mandatory: row.mandatory,
          reminder: row.reminder,
          reminder_content: row?.reminder_content,
        };
      }

      if (!selectedTraining) {
        const order = index + 1;
        trainingData['draft' + order] = {
          name: 'draft' + order,
          date: row.date.toISOString(),
          step: row.step,
          mandatory: row.mandatory,
          reminder: row.reminder,
          reminder_content: row?.reminder_content,
        };
      }
    });

    const dataBroadcast = {
      name: title,
      description: content,
      type: selectionValue,
      subscriptionControl: isUnsubscribe,
      recipients: selectedLearnerNames,
      trainings: trainingData,
      status: newStatus,
      manual_broadcast: {
        max_trainings: menuLimits.max_trainings,
        min_trainings: menuLimits.min_trainings,
      },
    };

    if (!dataBroadcast.manual_broadcast.max_trainings)
      delete dataBroadcast.manual_broadcast;

    try {
      await BroadcastService.updateBroadcast(id, dataBroadcast);
      handleClose();
      getBroadcasts();
      resetTable();
    } catch (e) {
      setSnackbarContent('error', e.response.data.message);
    }
  };

  const calculateTimeDifference = (trainingDate, reminderDate) => {
    const diffInMillis = dayjs(reminderDate).diff(dayjs(trainingDate));

    const diffDuration = dayjs.duration(diffInMillis);
  
    const days = Math.floor(diffDuration.asDays());
    const hours = diffDuration.hours();

    return {
      days, hours
    }
  };

  const handleSelectionChange = (event) => {
    setSelectionValue(event.target.value);

    if (event.target.value === diffuserTypes.timing.value) {

      const updatedTableData = tableData.map((training, index) => {
        // Get steps in days and hours for Continu Trainings in case if user changes Type of Broadcast
        if (index && training.date && training?.reminder_content?.date) {
          const { days, hours } = calculateTimeDifference(training.date, training.reminder_content.date)

          return {
            ...training,
            reminder_content: {
              ...training.reminder_content,
              stepsDays: days || 0,
              stepsHours: hours || 0,
            }
          }
        }

        return training
      })
      
      setTableData(updatedTableData)
    }
  };

  const changeTraining = (e, index) => {
    const updatedTable = tableData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          selectValue: e.target.value,
          reminder_content: {
            content: '',
            date: dayjs().add(4, 'hour'),
            stepsDays: 0,
            stepsHours: 0,
          },
          reminder: false,
          mandatory: false,
        };
      }
      return item;
    });

    setTableData(updatedTable);
  };

  const changeAction = (checked, index, type) => {
    const updatedTable = tableData.map((item, i) => {
      const isMandatory = type === 'mandatory';

      if (i === index) {
        return isMandatory
          ? {
              ...item,
              mandatory: !checked,
            }
          : {
              ...item,
              reminder: !checked,
            };
      }
      return item;
    });
    setTableData(updatedTable);
  };

  const AddRow = () => {
    const newRow = {
      id: counter + 1,
      selectValue: '',
      step: 1,
      content: '',
      mandatory: false,
      reminder: false,
      date: tableData[tableData.length - 1].date.add(1, 'day'),
      trainingId: null,
      // date: tableData[tableData.length - 1].date.add(10, 'minute'),
    };

    newRow.reminder_content = {
      content: '',
      date: newRow.date.add(4, 'hour'),
      stepsDays: 0,
      stepsHours: 0,
    };

    setTableData((prev) => [...prev, newRow]);
    setCounter((prev) => prev + 1);
  };

  const handleDaysChange = (event, row, index) => {
    const days = +event.target.value;

    if (typeof days === 'number' && !isNaN(days)) {
      const updatedTable = tableData.map((item, i) => {
        const daysDifference = row.step - days;

        const isPrevDaysBigger = row.step > days;
        if (i === index) {

          if (dayjs(row?.date).isBefore(dayjs())) row.date = dayjs()

          return {
            ...item,
            step: days,
            date: isPrevDaysBigger
              ? dayjs(row.date).subtract(daysDifference, 'day')
              : dayjs(row.date).add(Math.abs(daysDifference), 'day'),
            reminder_content:  {
              ...item.reminder_content,
              date:  isPrevDaysBigger
              ? dayjs(row.reminder_content.date).subtract(daysDifference, 'day').add(row.step || 0, 'day')
              : dayjs(row.reminder_content.date).add(Math.abs(daysDifference), 'day').add(row.step || 0, 'day'),
            }
          };
        }

        if (i > index) {

          if (dayjs(item?.date).isBefore(dayjs())) item.date = dayjs()

          return {
            ...item,
            date: isPrevDaysBigger
              ? dayjs(item.date).subtract(Math.abs(daysDifference), 'day')
              : dayjs(item.date).add(Math.abs(daysDifference), 'day'),
            reminder_content: {
              ...item.reminder_content,
              date: isPrevDaysBigger
              ? dayjs(item.reminder_content.date).subtract(Math.abs(daysDifference), 'day').add(item.step || 0, 'day')
              : dayjs(item.reminder_content.date).add(Math.abs(daysDifference), 'day').add(item.step || 0, 'day'),
            }
          };
        }

        return item;
      });
      setTableData(updatedTable);
    }
  };

  const RemoveRow = (index) => {
    const temp = [...tableData];
    temp.splice(index, 1);
    setTableData(temp);
  };

  const handleDateChange = (date, index) => {
    const updatedTable = tableData.map((item, i) => {
      if (i === index) {
        const isReminderActivated = item.reminder;

        const prev = {
          ...item,
          date: date,
        };

        if (!isReminderActivated) {
          if (prev.reminder_content)
            prev.reminder_content.date = date.add(4, 'hour');
          else
            prev.reminder_content = {
              date: date.add(4, 'hour'),
              stepsDays: 0,
              stepsHours: 0,
            };
        }

        return prev;
      }
      return item;
    });
    setTableData(updatedTable);
  };

  const getContentForTraining = (selectedTrainingName, isFullText = false) => {
    const selectedTraining = trainings.find(
      (training) => training.name === selectedTrainingName
    );
    if (selectedTraining && isFullText) {
      return selectedTraining.content;
    }

    if (selectedTraining) {
      const content = extractTextAndLink(selectedTraining.content);
      return content?.text || selectedTraining.content;
    }
    return '';
  };

  const getBroadcastId = async () => {
    const response = await BroadcastService.getBroadcastById(id);
    const broadcastData = response.data.data;

    setIsUnsubscribe(response.data.data.subscriptionControl || false);

    const personalBroadcasts = broadcastData.personal_registrations
      .filter(
        (broadcast) => broadcast.status !== diffuserStatuses.UNSUBSCRIBED.value
      )
      .map((broadcast) => broadcast.recipients[0]);

    setTitle(broadcastData.name);
    setContent(broadcastData.description);
    const newTableData = broadcastData.trainings.map((training, index) => ({
      id: training.id,
      selectValue: training.name,
      mandatory: training.mandatory || false,
      reminder: training.reminder || false,
      step: training.step ? training.step : index === 0 ? 0 : 1,
      date: dayjs(training.date),
      reminder_content: training?.reminder_content ? { stepsDays: 0, stepsHours: 0, ...training.reminder_content } : { content: '', date: dayjs(training.date), stepsDays: 0, stepsHours: 0, },
    }));
    setSelectionValue(broadcastData.type);
    setTableData(newTableData);
    setStataus(broadcastData.status);
    setSelectedLearnerNames(broadcastData.recipients);
    setPersonalBroadcasts(personalBroadcasts);
    if (broadcastData.type === diffuserTypes.manual.value) {
      setMenuLimits(broadcastData.manual_broadcast);
    }
  };

  const cancelLaunchBroadcast = () => {
    setConfirmLaunch(false);
    if (setConfirmedBroadcastId) {
      setConfirmedBroadcastId(null);
    }
  };

  const launchBroadcast = async () => {
    if (type === actionTypes.create) {
      await createDiffuser(diffuserStatuses.ACTIVE.value);
    }

    if (type === actionTypes.edit && confirmCreateBroadcast) {
      updateDiffuser(diffuserStatuses.ACTIVE.value);
    }

    setConfirmLaunch(false);
  };

  const prepareLaunchBroadcast = async () => {
    if (
      selectionValue === diffuserTypes.manual.value &&
      (!menuLimits.min_trainings ||
        !menuLimits.max_trainings ||
        menuLimits.min_trainings > menuLimits.max_trainings)
    ) {
      return setSnackbarContent(
        'error',
        'Veuillez sélectionner le nombre approprié de formations'
      );
    }

    setConfirmLaunch(true);
    const transformedTrainings = tableData.reduce((result, item) => {
      const foundTraining = trainings.find(
        (training) => training.name === item.selectValue
      );

      result[foundTraining._id] = {
        name: item.selectValue,
        date: item.date,
        step: item.step,
        mandatory: item.mandatory,
        reminder: item.reminder,
      };
      return result;
    }, {});

    const payload = {
      name: title,
      description: content,
      type: selectionValue,
      company_id: selectedOrganizationId,
      status: diffuserActionTypes.DRAFT.value,
      recipients: selectionValue === diffuserTypes.specific.value ? selectedLearnerNames : [],
      trainings: transformedTrainings,
      subscriptionControl: isUnsubscribe,
    };

    try {
      const response = await BroadcastService.calculateCredits(payload);
      setCreditsInfo(response?.data?.data);
    } catch (e) {
      console.error(e);
    }
  };

  const changeMenuLimits = (e, type) => {
    if (type === 'max') {
      setMenuLimits((prev) => ({ ...prev, max_trainings: e.target.value }));
    }

    if (type === 'min') {
      setMenuLimits((prev) => ({ ...prev, min_trainings: e.target.value }));
    }
  };

  // Calculate Errors
  useEffect(() => {
    if (open) {
      calculateErrorFromDates(tableData, selectionValue, setCoptTableToCompare)
    }
  }, [tableData]);

  const isTitleEmpty = title.trim() === '';
  const isContentEmpty = content.trim() === '';

  const startDate = dayjs(tableData[0].date);
  const today = dayjs()

  const isValidSpecific =
    startDate &&
    startDate.isAfter(today) &&
    selectionValue === diffuserTypes.specific.value;

  return (
    <>
      {type === actionTypes.create && (
        <Button onClick={handleOpen} variant="contained" color="primary">
          AJOUTER
        </Button>
      )}

      {type === actionTypes.edit && !text ? (
        <EditButton handleOpen={handleOpen} disabled={disabled} />
      ) : null}

      {type === actionTypes.edit && text ? (
        <span className="text-link" onClick={handleOpen}>
          {text}
        </span>
      ) : null}

      <Dialog
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
        maxWidth="lg"
        scroll={'paper'}
      >
        <DialogTitleCustom id="modal-modal-title">
          {type === actionTypes.create && 'Créer un parcours'}
          {type === actionTypes.edit && 'Modifier un parcours'}
        </DialogTitleCustom>
        <DialogContent>
          <Box>
            Nom (sera lu par les apprenants)
            <TextField
              autoComplete="off"
              label=""
              variant="outlined"
              sx={{ width: '100%', marginTop: '10px' }}
              value={title}
              onChange={handleTitle}
              error={isTitleEmpty}
            />
          </Box>
          <Box marginTop={'30px'}>Description (sera lu par les apprenants)</Box>
          <TextField
            label=""
            autoComplete="off"
            variant="outlined"
            sx={{ width: '100%', marginTop: '10px', marginBottom: '20px' }}
            multiline
            rows={5}
            value={content}
            onChange={handleContent}
            error={isContentEmpty}
          />

          <FormControl
            disabled={
              (type === actionTypes.edit &&
              status !== diffuserStatuses.DRAFT.value) ||type === actionTypes.edit
            }
          >
            <RadioGroup
              row
              value={selectionValue}
              onChange={handleSelectionChange}
            >
              {Object.values(diffuserTypes).map((type) => {
                return (
                  <FormControlLabel
                    value={type.value}
                    key={type.value}
                    label={type.label}
                    control={<Radio />}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <Box sx={{ marginTop: '10px' }}>
            <TypographyUI color="black" fontSize="16px" fontWeight={500}>
              Sélection des contenus
            </TypographyUI>
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginLeft: '-9px' }}
            >
              <Switch
                checked={isUnsubscribe}
                onChange={() => setIsUnsubscribe((prev) => !prev)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <TypographyUI color="black" fontSize="16px" fontWeight={500}>
                Envoi d'un sms de désinscription (décoché par défaut)
              </TypographyUI>
              <Tooltip
                title={
                 <>
                  <div>Le bénéficiaire s'inscrit via un formulaire, il reçoit le SMS dans la foulée.</div>
                  <div>Le bénéficiaire est inscrit via la base de données par l'admin, il reçoit le SMS lorsque le parcours est activé par l'admin.</div></>
                }
                placement="left"
              >
                <i
                  className="fa fa-fas fa-question-circle"
                  style={{ color: '#3333cc', marginLeft: '5px' }}
                ></i>
              </Tooltip>
            </Box>
          </Box>

          {selectionValue === diffuserTypes.manual.value && (
            <Box>
              <Typography marginTop="10px">
                Nombre de contenus que le bénéficiaire peut sélectionner
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <FormControl
                  sx={{ m: '8px 8px 8px 0', width: '100%', maxWidth: 250 }}
                  error={
                    menuLimits.min_trainings &&
                    menuLimits.max_trainings &&
                    menuLimits.min_trainings > menuLimits.max_trainings
                  }
                >
                  <InputLabel>Minimum de contenus</InputLabel>
                  <Select
                    value={menuLimits.min_trainings}
                    label="Minimum de contenus"
                    onChange={(e) => changeMenuLimits(e, 'min')}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                      <MenuItem value={num}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: '100%', maxWidth: 250 }}
                  error={
                    menuLimits.max_trainings &&
                    menuLimits.min_trainings &&
                    menuLimits.min_trainings > menuLimits.max_trainings
                  }
                >
                  <InputLabel>Maximum de contenus</InputLabel>
                  <Select
                    value={menuLimits.max_trainings}
                    label="Maximum de contenus"
                    onChange={(e) => changeMenuLimits(e, 'max')}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                      <MenuItem value={num}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Ordre</TableCell>
                  <TableCell align="center">
                    <Box>Micro-formation</Box>
                    {selectionValue === diffuserTypes.manual.value && (
                      <Box>Diffusion quotidienne</Box>
                    )}
                  </TableCell>
                  {selectionValue !== diffuserTypes.manual.value && (
                    <TableCell align="center">Planning de diffusion</TableCell>
                  )}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index, arr) => {
                  const formattedDate = row.date;

                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center">{index + 1}</TableCell>

                      {selectionValue === diffuserTypes.manual.value ? (
                        <TableCell align="center">
                          <Select
                            value={row.selectValue}
                            disabled={
                              type === actionTypes.edit &&
                              (status === diffuserStatuses.COMPLETED.value ||
                                status === diffuserStatuses.CLOSED.value)
                            }
                            onChange={(e) => changeTraining(e, index)}
                            sx={{
                              marginLeft: '10px',
                              width: 300,
                              textAlign: 'left',
                            }}
                          >
                            {trainings.map((training) => (
                              <MenuItem
                                key={training._id}
                                value={training.name}
                                disabled={tableData.some(
                                  (tableTraining) =>
                                    tableTraining.selectValue === training.name
                                )}
                              >
                                {training.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <Select
                            value={row.selectValue}
                            disabled={
                              type === actionTypes.edit &&
                              (status === diffuserStatuses.COMPLETED.value ||
                                status === diffuserStatuses.CLOSED.value)
                            }
                            onChange={(e) => changeTraining(e, index)}
                            sx={{
                              marginLeft: '10px',
                              width: 300,
                              textAlign: 'left',
                            }}
                          >
                            {trainings.map((training) => (
                              <MenuItem
                                key={training._id}
                                value={training.name}
                                disabled={tableData.some(
                                  (tableTraining) =>
                                    tableTraining.selectValue === training.name
                                )}
                              >
                                {training.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      )}
                      {selectionValue !== diffuserTypes.manual.value && (
                        <TableCell
                          align="center"
                          sx={{ display: 'flex', gap: 1 }}
                        >
                          {selectionValue === diffuserTypes.specific.value && (
                            <>
                              <DateTimePicker
                                className="full-width"
                                ampm={false}
                                label="Date"
                                timeSteps={{ hours: 1, minutes: 1 }}
                                value={formattedDate}
                                onChange={(date) =>
                                  handleDateChange(date, index)
                                }
                                disablePast={true}
                                // shouldDisableDate={(date) => isDisabledDate(date, ANEMON_HOLIDAYS)}
                                disabled={
                                  type === actionTypes.edit &&
                                  (status ===
                                    diffuserStatuses.COMPLETED.value ||
                                    status === diffuserStatuses.CLOSED.value)
                                }
                                minDateTime={
                                  index > 0
                                    ? // ? arr[index - 1].date.add(1, 'day')
                                      arr[index - 1].date.add(1, 'minute')
                                    : dayjs()
                                }
                                // maxDateTime={
                                //   type === actionTypes.edit ? undefined :
                                //   tableData[index + 1]
                                //     ? tableData[index + 1].date.subtract(
                                //         1,
                                //         'minute'
                                //       )
                                //     : undefined
                                // }
                                components={{
                                  OpenPickerIcon: ({ ...other }) => (
                                    <CalendarIcon
                                      style={{ color: '#66DEAE' }}
                                      {...other}
                                    />
                                  ),
                                }}
                              />
                            </>
                          )}
                          {selectionValue === diffuserTypes.timing.value &&
                          index === 0 ? (
                            <>
                              <DateTimePicker
                                className="full-width"
                                ampm={false}
                                fullWidth
                                label="Les inscriptions sont ouvertes à partir de:"
                                timeSteps={{ hours: 1, minutes: 1 }}
                                value={formattedDate}
                                onChange={(date) =>
                                  handleDateChange(date, index)
                                }
                                disablePast={type === actionTypes.create}
                                // shouldDisableDate={(date) => isDisabledDate(date, ANEMON_HOLIDAYS)}
                                disabled={
                                  type === actionTypes.edit &&
                                  (status ===
                                    diffuserStatuses.COMPLETED.value ||
                                    status === diffuserStatuses.CLOSED.value)
                                }
                                minDateTime={
                                  index > 0
                                    ? arr[index - 1].date.add(1, 'minute')
                                    : dayjs()
                                }
                                components={{
                                  OpenPickerIcon: ({ ...other }) => (
                                    <CalendarIcon
                                      style={{ color: '#66DEAE' }}
                                      {...other}
                                    />
                                  ),
                                }}
                              />
                            </>
                          ) : selectionValue === diffuserTypes.timing.value &&
                            index ? (
                            <>
                              <TextField
                                value={row.step}
                                fullWidth
                                disabled={
                                  type === actionTypes.edit &&
                                  (status ===
                                    diffuserStatuses.COMPLETED.value ||
                                    status === diffuserStatuses.CLOSED.value)
                                }
                                onChange={(event) =>
                                  handleDaysChange(event, row, index)
                                }
                                error={!row.step}
                              />
                            </>
                          ) : null}
                        </TableCell>
                      )}
                      <TableCell align="center" sx={{ textAlign: 'left' }}>
                        <CreateEditDiffuserTableActions
                          index={index}
                          row={row}
                          isMandatoryDisabled={
                            type === actionTypes.edit &&
                            (status === diffuserStatuses.COMPLETED.value ||
                              status === diffuserStatuses.CLOSED.value)
                          }
                          getContentForTraining={getContentForTraining}
                          RemoveRow={RemoveRow}
                          changeAction={changeAction}
                          tableData={tableData}
                          setTableData={setTableData}
                          copyTableToCompare={copyTableToCompare}
                          diffuserType={selectionValue}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <AddDayIntoTable
            AddRow={AddRow}
            disabled={
              type === actionTypes.edit &&
              (status === diffuserStatuses.COMPLETED.value ||
                status === diffuserStatuses.CLOSED.value)
            }
          />

          <Box marginTop={'15px'}>
            {type === actionTypes.create && (
              <SelectRecipient
                disabled={(
                  selectionValue === diffuserTypes.timing.value) || !isValidSpecific ||
                  selectionValue === diffuserTypes.manual.value
                }
              />
            )}
            {type === actionTypes.edit && (
              <EditSelectRecipients
                broadcastId={id}
                disabled={
                  selectionValue === diffuserTypes.timing.value || !isValidSpecific ||
                  selectionValue === diffuserTypes.manual.value ||
                  (type === actionTypes.edit &&
                    (status === diffuserStatuses.COMPLETED.value ||
                      status === diffuserStatuses.CLOSED.value))
                }
                initialSelectedLearners={selectedLearnerNames}
                personalBroadcasts={personalBroadcasts}
                setPersonalBroadcasts={setPersonalBroadcasts}
              />
            )}
          </Box>

          <Typography variant="body1" marginTop="20px">
            Nombre de destinataires du parcours :{' '}
            {selectionValue === diffuserTypes.specific.value
              ? selectedLearnerNames.length + personalBroadcasts.length
              : 'sur inscription'}
          </Typography>

          <Dialog open={confirmLaunch} maxWidth={'md'}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <Typography>
                {diffuserActionTypes.DRAFT.text(
                  selectionValue,
                  undefined,
                  creditsInfo
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={cancelLaunchBroadcast}
                variant="outlined"
                color="error"
              >
                Annuler
              </Button>
              <Button
                onClick={() => launchBroadcast()}
                variant="contained"
                color="primary"
              >
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>

        <DialogActions>
          <DiffuserFooterActions
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isTitleEmpty={isTitleEmpty}
            isContentEmpty={isContentEmpty}
            isSubmitBtnDisabled={isSubmitBtnDisabled}
            selectionValue={selectionValue}
            disabled={
              (type === actionTypes.edit &&
                (status === diffuserStatuses.COMPLETED.value ||
                  status === diffuserStatuses.CLOSED.value)) ||
              (
                tableData.some((project) => project.selectValue === '')) ||
              (selectionValue !== diffuserTypes.manual.value &&
                (copyTableToCompare.some(
                  (project) => project.isError === true
                ) ||
                (selectionValue !== diffuserTypes.manual.value && copyTableToCompare.some(
                  (project) => project.reminder === true && project.reminder_content.isError === true)
                )))
            }
            prepareLaunchBroadcast={prepareLaunchBroadcast}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateEditDiffuser;
